<template>
  <div birthday-selector>
    <Selector :list="yearList" v-model="year" :placeholder="$t('yearOfBirth')" @change="changed" />
    <Selector :list="monthList" v-model="month" :placeholder="$t('month')" @change="changed" />
    <Selector :list="dateList" v-model="day" :placeholder="$t('day')" @change="changed" />
  </div>
</template>

<script>
import moment from 'moment';
import Selector from '@shared/components/common/Selector.vue';
import { datesOfMonth, range } from '@shared/utils/commonUtils';

export default {
  name: 'BirthdaySelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  components: { Selector },
  props: {
    adult: { type: Boolean, default: false },
    format: { type: String, default: 'YYYY-MM-DD' },
    value: { type: String, default: null },
  },
  data() {
    return {
      year: null,
      month: null,
      day: null,
    };
  },
  computed: {
    yearList() {
      const lastYear = new Date().getFullYear() - (this.adult ? 20 : 0);
      return range(lastYear, lastYear - (this.adult ? 80 : 100));
    },
    monthList() {
      return range(1, 12);
    },
    dateList() {
      return datesOfMonth(this.year, this.month);
    },
  },
  methods: {
    changed() {
      let v;
      if (!this.year || !this.month || !this.day) {
        if (!this.value) return;
        v = null;
      } else {
        v = moment([this.year, this.month - 1, this.day]).format(this.format);
      }
      this.$emit('change', v);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[birthday-selector] { .flex;
  [selector] { flex: 1.5; .z(1); }
  [selector] + [selector] { flex: 1; .ml(8); }
}
</style>
