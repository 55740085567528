<template>
  <div representative>
    <h2 class="hidden-ml-down">REPRESENTATIVE VERIFICATION</h2>
    <h3>{{ $t('_.title') }}</h3>
    <p v-t:_.underFourteen />
    <ValidationObserver class="on-modal" ref="validator">
      <template v-for="key in keys">
        <ValidationProvider :key="toConcatName(key)" rules="required" :name="toConcatName(key)" v-slot="{errors}">
          <div :class="{error: errors.length}">
            <label>{{ $t(toConcatName('_.', key)) }}</label>
            <input type="text" v-model="info[key].name" :placeholder="$t(toConcatName('_.', key))">
            <ValidationErrors :errors="errors" />
          </div>
        </ValidationProvider>
        <ValidationProvider :key="toConcatBirthDate(key)" rules="required" :name="toConcatBirthDate(key)" v-slot="{errors}" class="has-selector">
          <div :class="{error: errors.length}">
            <label>{{ $t(toConcatBirthDate('_.', key)) }}</label>
            <BirthdaySelector v-model="info[key].birthDate" :adult="key === 'parent'" :class="`${key}-birthday-selector`" />
            <ValidationErrors :errors="errors" class="no-line" />
          </div>
        </ValidationProvider>
      </template>
      <button @click="verify">
        <img src="/img/signup/ico_verf_phone.svg" alt="phone verification">
        <span>{{ $t('_.phoneVerification') }}</span>
      </button>
      <p>{{ $t('_.privacyPolicyArticle_22_5') }}</p>
    </ValidationObserver>
  </div>
</template>

<script>
import BirthdaySelector from '@shared/components/common/BirthdaySelector.vue';

export default {
  name: 'Representative',
  lexicon: 'account.representative',
  components: { BirthdaySelector },
  props: {
    reverification: { type: Boolean, default: false },
  },
  data: () => ({
    keys: ['my', 'parent'],
    info: {
      my: {
        name: '',
        birthDate: '',
      },
      parent: {
        name: '',
        birthDate: '',
      },
    },
  }),
  methods: {
    async verify() {
      await this.timeoutShield();
      await this.$validate(this.$refs.validator);
      const { my, parent } = this.info;
      const certificateRequest = /** @type {ParentCertificateRequest} */ {
        impUid: await this.$services.auth.getImpToken(),
        ...parent,
        child: { ...my },
      };
      if (this.reverification) {
        await this.$services.auth.reverification('PARENTS_IAMPORT', certificateRequest);
        await this.routeName('UserSummary', { user: 'me' });
      } else {
        await this.$services.auth.signup('PARENTS_IAMPORT', certificateRequest);
      }
    },
    concat(...keys) {
      return keys.reduce((acc, key) => acc.concat(key), '');
    },
    toConcatName(...keys) {
      return this.concat(...keys, 'Name');
    },
    toConcatBirthDate(...keys) {
      return this.concat(...keys, 'Birthday');
    },
  },
  async afterAsyncData({ services, route }) {
    if (route.name !== 'ReverificationRepresentative' && !services.auth.loginMethodRegistered) {
      throw { code: 302, to: 'membership-agreement' };
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[representative] {
  h3 + p { .-b(@c-base-gray); .mb(24); .pb(20); .tc;
    em { .c(#df3a3a); .underline; .bold; }
  }
  .my-birthday-selector [selector] { .z(2); }
  fieldset {
    > button { .wh(100%, 140); .br(6); .-a(#d5d5de); .flex-center; .bgc(#fff); .o(0.5); .c(#787781); .fs(14); .mt(20);
      @{hover-press} { .-a(#787781); }
      span { .ml(28); }
    }
    > p { .fs(12); .c(@c-placeholder); .tc; .m(20, 0, 50); }
  }
}
</style>
